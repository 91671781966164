<template>
  <div class="features-container">
    <div class="title">
      Gerenciamento de features
    </div>
    <div>
      <FeaturesTable
        :features="features"
        @openModal="openFeatureModal"
        @delete="onDeleteFeature"
      />
    </div>

    <div class="divider" />

    <div class="title">
      Features vínculadas a clínicas
    </div>

    <div>
      <b-form-input
        v-model="search"
        debounce="300"
        placeholder="Buscar por nome da clínica ou nome do produto..."
      />
      <ClinicFeaturesTable
        :clinicFeatures="clinicFeatures"
        @openModal="openClinicFeatureModal"
        @delete="onDeleteClinicFeature"
      />
      <div class="pagination-position">
        <b-pagination
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
          size="sm"
        />
      </div>
    </div>

    <FeatureFormModal
      :feature="feature"
      @onHidden="getAllFeatures"
    />

    <ClinicFeatureFormModal
      :clinics="clinics"
      :clinicFeature="clinicFeature"
      :features="features"
      @onHidden="getClinicFeatures"
    />
  </div>
</template>
<script>
import FeaturesTable from '@/components/Features/FeaturesTable'
import ClinicFeaturesTable from '@/components/Features/ClinicFeaturesTable'
import FeatureFormModal from '@/components/Features/FeatureFormModal'
import ClinicFeatureFormModal from '@/components/Features/ClinicFeatureFormModal'

export default {
  props: {
    clinics: Array,
  },
  components: { FeaturesTable, ClinicFeaturesTable, FeatureFormModal, ClinicFeatureFormModal },
  mounted() {
    this.getAllFeatures()
    this.getClinicFeatures()
  },
  data() {
    return {
      feature: null,
      clinicFeature: null,
      features: [],
      clinicFeatures: [],
      search: null,
      page: 1,
      count: 0,
      limit: 0,
    }
  },
  methods: {
    openFeatureModal(feature) {
      this.feature = feature
      this.$bvModal.show('feature-form-modal')
    },
    async onDeleteFeature(feature) {
      const loading = this.$loading.show()
      try {
        await this.api.deleteFeature(feature.id)
        this.$toast.success('Produto excluído com sucesso')
        this.getAllFeatures()
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async onDeleteClinicFeature(clinicFeature) {
      const loading = this.$loading.show()
      try {
        await this.api.deleteClinicFeature(clinicFeature.id)
        this.$toast.success('Vínculo excluído com sucesso')
        this.getClinicFeatures()
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    openClinicFeatureModal(clinicFeature) {
      this.clinicFeature = clinicFeature
      this.$bvModal.show('clinic-feature-form-modal')
    },
    getAllFeatures() {
      this.api.getAllFeatures()
        .then(res => {
          this.features = res.data
        })
    },
    getClinicFeatures() {
      this.api.getClinicFeatures(this.page, this.search)
        .then(res => {
          this.clinicFeatures = res.data.data
          this.count = res.data.total
          this.limit = res.data.per_page
        })
    }
  },
  watch: {
    search() { this.getClinicFeatures() },
    page() { this.getClinicFeatures() }
  }
}
</script>
<style lang="scss" scoped>
.features-container {
  padding: 24px;
}
.divider {
  width: 100%;
  height: 1px;
  margin: 30px 0;
  background-color: var(--neutral-200);
}
</style>
