<template>
  <div>
  <b-table-simple class="table" striped>
    <thead>
      <tr>
        <td class="td-header full-width">Nome</td>
        <td class="td-header">Chave</td>
        <td>
          <div class="actions">
            <Icon tooltip="Nova feature">
              <Plus class="icon-plus" @click="$emit('openModal', null)" />
            </Icon>
          </div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="feature in features" :key="feature.id">
        <td class="full-width">{{ feature.name }}</td>
        <td>{{ feature.key }}</td>
        <td>
          <div class="actions">
            <toggle-button
              class="autoFillBtn"
              v-model="feature.active"
              :sync="true"
              :height="32"
              :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
              @change="(props) => changeFeatureActive(feature, props)"
            />
            <Icon tooltip="Editar feature">
              <Edit class="icon" @click="$emit('openModal', feature)" />
            </Icon>
            <Icon tooltip="Excluir feature">
              <Delete class="icon" @click="onDelete(feature)" />
            </Icon>
          </div>
        </td>
      </tr>
    </tbody>
  </b-table-simple>
  </div>
</template>
<script>
import Icon from '@/components/General/Icon'
import Plus from '@/assets/icons/plus.svg'
import Edit from '@/assets/icons/edit.svg'
import Delete from '@/assets/icons/delete.svg'

export default {
  components: { Icon, Plus, Edit, Delete },
  props: {
    features: Array,
  },
  methods: {
    onDelete(feature) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir o Produto <span>${feature.name}</span>? Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.$emit('delete', feature)
        }
      })
    },
    async changeFeatureActive(feature, { value }) {
      try {
        await this.api.updateFeature(feature.id, { active: value })
        this.$toast.success('Status atualizado com sucesso')
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.table {
  .td-header {
    font-weight: 700;
    color: var(--active-type);
  }
  td {
    border-top: none;
    vertical-align: middle;
  }
  .full-width {
    width: 100%;
  }
}
.icon-plus {
  width: 24px;
  height: 24px;
  fill: var(--blue-700);
  cursor: pointer;
}
.actions {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>
