<template>
  <div>
  <b-table-simple class="table" striped>
    <thead>
      <tr>
        <td class="td-header">Clínica</td>
        <td class="td-header">Produto</td>
        <td class="td-header">Valor</td>
        <td>
          <div class="actions">
            <Icon tooltip="Novo vínculo">
              <Plus class="icon-plus" @click="$emit('openModal', null)" />
            </Icon>
          </div>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr v-for="clinicFeature in clinicFeatures" :key="clinicFeature.id">
        <td>{{ clinicFeature.clinic?.name }}</td>
        <td>{{ clinicFeature.feature.name }}</td>
        <td>{{ parseNumberToMoney(clinicFeature.price) }}</td>
        <td>
          <div class="actions">
            <Icon tooltip="Editar produto">
              <Edit class="icon" @click="$emit('openModal', clinicFeature)" />
            </Icon>
            <Icon tooltip="Excluir produto">
              <Delete class="icon" @click="onDelete(clinicFeature)" />
            </Icon>
          </div>
        </td>
      </tr>
    </tbody>
  </b-table-simple>
  </div>
</template>
<script>
import { parseNumberToMoney } from '../../utils/moneyHelper'
import Icon from '@/components/General/Icon'
import Plus from '@/assets/icons/plus.svg'
import Edit from '@/assets/icons/edit.svg'
import Delete from '@/assets/icons/delete.svg'

export default {
  components: { Icon, Plus, Edit, Delete },
  props: {
    clinicFeatures: Array,
  },
  methods: {
    parseNumberToMoney,
    onDelete(clinicFeature) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente excluir o vínculo entre <span>${clinicFeature.clinic.name}</span> e <span>${clinicFeature.feature.name}</span>? Essa ação não poderá ser desfeita.</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.$emit('delete', clinicFeature)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  .td-header {
    font-weight: 700;
    color: var(--active-type);
  }
  td {
    border-top: none;
    vertical-align: middle;
  }
  .full-width {
    width: 100%;
  }
}
.icon-plus {
  width: 24px;
  height: 24px;
  fill: var(--blue-700);
  cursor: pointer;
}
.actions {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    float: right;
  }
}
</style>
