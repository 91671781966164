<template>
  <b-modal
    id="feature-form-modal"
    size="md"
    centered
    hide-header
    hide-footer
    @hidden="onHidden"
  >
    <div class="header">
      <div class="header-content">
        <p>{{ form.id ? 'Atualizar' : 'Nova' }} feature</p>
        <Close class="close-svg" @click="$bvModal.hide('feature-form-modal')" />
      </div>
    </div>
    <div class="body">

      <b-row>
        <b-col xs="12">
          <b-form-group>
            <label for="name">Nome</label>
            <b-form-input
              id="name"
              autocomplete="off"
              v-model="form.name"
            />
            <div v-if="verified && !form.name" class="custom-invalid-feedback">Campo obrigatório</div>
          </b-form-group>
        </b-col>
        <b-col xs="12">
          <b-form-group>
            <label for="key">Chave</label>
            <b-form-input
              id="key"
              autocomplete="off"
              v-model="form.key"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="actions">
        <b-button
          variant="outline-secondary"
          @click="$bvModal.hide('feature-form-modal')"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="form.id ? updateProduct() : saveProduct()"
        >
          Salvar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Close from '@/assets/icons/close.svg'

export default {
  components: { Close },
  props: {
    feature: Object
  },
  data() {
    return {
      verified: false,
      form: {},
    }
  },
  methods: {
    onHidden() {
      this.form = {}
      this.$emit('onHidden')
    },
    isValid() {
      this.verified = true
      return this.form.name
    },
    getProps() {
      return {
        name: this.form.name,
        key: this.form.key,
      }
    },
    async saveProduct() {
      if (!this.isValid()) return
      try {
        await this.api.createFeature(this.getProps())
        this.$toast.success('Produto criado com sucesso')
        this.$bvModal.hide('feature-form-modal')
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
    async updateProduct() {
      if (!this.isValid()) return
      try {
        await this.api.updateFeature(this.form.id, this.getProps())
        this.$toast.success('Produto atualizado com sucesso')
        this.$bvModal.hide('feature-form-modal')
      } catch(err) {
        this.$toast.error(err.message)
      }
    }
  },
  watch: {
    feature(val) {
      this.form = val
    }
  }
}
</script>
<style lang="scss">
#feature-form-modal {

  .modal-body {
    padding: 0;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--type-active);

    .header {
      border-bottom: 1px solid var(--neutral-200);

      .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 24px;

        .close-svg {
          height: 24px;
          width: 24px;
          fill: var(--neutral-500);
        }
      }
    }
    .body {
      padding: 24px;
      color: var(--type-active);
    }
    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      padding-top: 24px;
    }
  }

}
</style>
